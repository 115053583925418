import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' //引入store
import ElementUI from 'element-ui';
import directive from "/src/assets/change.js";
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.use(ElementUI, { size: 'small', zIndex: 200 });

// import animated from 'animate.css' // npm install animate.css --save安装，在引入
// Vue.use(animated)
Vue.config.productionTip = false
Vue.directive("change", directive);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app') 