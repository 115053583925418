const state = {
  menuList: [], //菜单列表
  activeIndex: "1", //当前下标
  activeMenu: '0',
};
const getters = {
  getMenu(state) {
    //获取访问该页面的所有用户
    // 对数据清理-除去脏数据
    if (state.menuList.includes("*")) delete state.menuList["*"];
    return state.menuList;
  },
  getindex(state) {
    //获取访问该页面的所有用户
    // 对数据清理-除去脏数据
    return state.activeIndex;
  },
  getActiveMenu(state) {
    return state.activeMenu
  }
};
const mutations = {
  setMenuList(state, list) {
    state.menuList = list;
  },
  setactiveIndex(state, index) {
    state.activeIndex = index;
  },
  setActiveMenu(state, menu) {
    state.activeMenu = menu
  }
};
const actions = {
  setMenu(store, name) {
    //触发mutations里面的setMenuList,传入数据形参name对应到menuList
    store.commit("setMenuList", name);
  },
  seIndex(store, index) {
    //触发mutations里面的setMenuList,传入数据形参index对应到menuList
    store.commit("setactiveIndex", index);
  },
  setActive(store, menu) {
    store.commit('setActiveMenu', menu)
  }
};
// 注意和仓库的区别
const store = {
  // namespaced用于在全局引用此文件里的方法时标识这一个的文件名，使得让人明白这些数据来自哪个仓库
  // 即当你需要在别的文件里面使用子仓库(mapStates、mapGetters、mapActions)时，里面的方法需要注明来自哪一个模块的方法
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default store;
