import request from "@/utils/request";

// 获取是否置灰
export const settingGray = () => {
  return request({
    url: '/api/websiteSysConfig/whetherSetGray',
    method: 'get'
  })
}

// 查询banner图列表
export const banners = () => {
  return request({
    url: "/api/websiteBanner/list",
    method: "get",
  });
};
// 查询公司动态列表
export const companyNews = () => {
  return request({
    url: "/api/websiteCompanyNews/list",
    method: "get",
  });
};
// 查询开发案例列表
export const cases = () => {
  return request({
    url: "/api/websiteDevelopmentcase/list",
    method: "get",
  });
};
// 获取开发案例详细
export const caseDetails = (data) => {
  return request({
    url: "/api/websiteDevelopmentcase/" + data,
    method: "get",
  });
};
// 查询关于我们列表
export const aboutUs = () => {
  return request({
    url: "/api/websiteAboutUs/list",
    method: "get",
  });
};
// 查询合作伙伴列表
export const partners = () => {
  return request({
    url: "/api/websitePartners/list",
    method: "get",
  });
};
// 查询行业动态列表
export const industryNews = () => {
  return request({
    url: "/api/websiteIndustryNews/list",
    method: "get",
  });
};
// 查询联系方式列表
export const contactList = () => {
  return request({
    url: "/api/websiteContactWay/list",
    method: "get",
  });
};
// 联系我们相关接口
export const contactUs = (data) => {
  return request({
    url: "/api/websiteContactUs",
    method: "post",
    data,
  });
};
// 查询加入我们列表
export const join = () => {
  return request({
    url: "/api/websiteJoinUs/list",
    method: "get",
  });
};
// 查询发展历程列表
export const history = () => {
  return request({
    url: "/api/websiteDevHis/list",
    method: "get",
  });
};
