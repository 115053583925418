<template>
	<div id="app">
		<!-- <router-view v-if="list.length > 0" /> -->
		<router-view />
		<div class="backtop">
			<el-backtop :bottom="100">
				<div class="b-btn"></div>
			</el-backtop>
		</div>
		<CallMe></CallMe>
	</div>
</template>

<script>
// import {
// 	getMenu
// } from "@/api/menu"
import { settingGray } from "@/api/api"
import CallMe from '@/components/callme.vue'
// import {
// 	mapGetters,
// 	mapActions
// } from 'vuex';
export default {
	name: 'App',
	components: { CallMe },
	// computed: {
	// 	...mapGetters('app', { list: 'getMenu' }),
	// },
	mounted() {
		window.addEventListener('beforeunload', this.handleBeforeUnload);
		// getMenu().then(res => {
		// 	this.setMenu(res.data)
		// })
		// 是否需要置灰
		settingGray().then(res => {
			console.log('settingGary==>', res)
			if (res.msg == "true") {
				let html_box = document.getElementById('html_box')
				html_box.style.filter = 'grayscale(100%)'
			} else {
				let html_box = document.getElementById('html_box')
				html_box.style.filter = 'grayscale(0%)'
			}
		})
	},
	created() {
		//云栖入口(需切换):
		window.localStorage.cpn = 'yunxi'
		//栈谷入口(需切换):
		// window.localStorage.cpn = 'zhangu'

		if (this.$route.path !== "/home")
			this.$router.replace("home")
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
	},
	methods: {
		handleBeforeUnload() {
			window.sessionStorage.menu = '0'
		},
		// ...mapActions('app', ['setMenu'])
	},
}
</script>

<style lang="less" scoped>
:root {
	--w756: 756px;
	--w980: 980px;
	--w1512: 1440px;
}

body,
html {
	margin: 0px;
	padding: 0px;
	display: block;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	position: relative;
	margin: 0px auto;
	padding: 0px;
	// width: 756*1.3px;

	.backtop {
		.el-backtop {
			border-radius: 0;
			background: #6EA2D5;
		}

		.b-btn {
			width: 32px;
			height: 32px;
			background-image: url('@/assets/imgs/backtop.png');
			background-size: 100% 100%;
		}
	}
}
</style>
<style>
@media screen and (min-width: 756px) {
	#app {
		width: 756px;
	}
}

@media screen and (min-width: 980px) {
	#app {
		width: 980px;
	}
}

@media screen and (min-width: 1280px) {
	#app {
		width: 1280px;
	}
}

@media screen and (min-width: 1440px) {
	#app {
		width: 1440px;
	}
}
</style>