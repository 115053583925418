<template>
    <div class="callme">
        <el-popover placement="left" width="100" trigger="hover">
            <el-image style="width: 100px; height: 100px" :src="qq" :fit="'fit'"></el-image>
            <div slot="reference">
                <img src="@/assets/imgs/qq.png" alt="">
            </div>
        </el-popover>
        <el-popover placement="left" width="100" trigger="hover">
            <h3>{{ phone }}</h3>
            <div slot="reference">
                <img src="@/assets/imgs/phone.png" alt="">
            </div>
        </el-popover>
        <el-popover placement="left" width="100" trigger="hover">
            <el-image style="width: 100px; height: 100px" :src="wechat" :fit="'fit'"></el-image>
            <div slot="reference">
                <img src="@/assets/imgs/wechat.png" alt="">
            </div>
        </el-popover>
        <!-- <img src="@/assets/imgs/qq.png" alt=""> -->
        <!-- <img src="@/assets/imgs/phone.png" alt=""> -->
        <!-- <img src="@/assets/imgs/wechat.png" alt=""> -->
    </div>
</template>

<script>
import config from "@/config.js"
import { contactList } from '@/api/api';
export default {
    name: 'CallMe',
    data() {
        return {
            qq: '',
            wechat: '',
            phone: '',
        }
    },
    created() {
        this.getContactList()
    },
    methods: {
        getContactList() {
            contactList().then(res => {
                console.log(res);
                let qq = res.rows.filter(i => { return i.name == 'QQ' })
                let wechat = res.rows.filter(i => { return i.name == '微信' })
                let phone = res.rows.filter(i => { return i.name == '电话' })
                let icp = res.rows.filter(i => { return i.name == 'ICP' })
                let gaba = res.rows.filter(i => { return i.name == '公安备案' })
                let links = res.rows.filter(i => { return i.name == '友情链接' })
                this.qq = qq[0] ? config.baseURL + qq[0].codePic : ''
                this.wechat = wechat[0] ? config.baseURL + wechat[0].codePic : ''
                this.phone = phone[0] ? phone[0].value : ''
                window.sessionStorage.phone = phone[0] ? phone[0].value : ''
                window.sessionStorage.icp = icp[0] ? icp[0].value : ''
                window.sessionStorage.gaba = gaba[0] ? gaba[0].value : ''
                window.sessionStorage.links = links[0] ? links[0].value : ''
                console.log('qq==>', qq);
                console.log('wechat==>', wechat);
                console.log('phone==>', phone);
            })
        },
    }

}
</script>

<style lang="less" scoped>
.callme {
    position: fixed;
    top: 215px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;

    img {
        display: block;
        overflow: hidden;
        padding: 5px;
        margin: 1px;
        width: 32px;
        height: 32px;
        opacity: .5;
        background: #6EA2D5;
    }
}
</style>