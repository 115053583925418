import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	routes: [{
			path: '/home',
			name: 'home',
			component: () => import('@/views/home/home'),
			meta: {
				title: '超市首页'
			}
		},
		{
			path: '/new',
			name: 'new',
			component: () => import('@/views/new/new'),
			meta: {
				title: '智慧政务'
			}
		},
		{
			path: '/logistics',
			name: 'logistics',
			component: () => import('@/views/logistics/logistics'),
			meta: {
				title: '物联平台'
			}
		},
		{
			path: '/project/open',
			name: 'open',
			component: () => import('@/views/project/open'),
			meta: {
				title: '软件开发'
			}
		},
		{
			path: '/project/app',
			name: 'app',
			component: () => import('@/views/project/app'),
			meta: {
				title: 'app开放'
			}
		},
		{
			path: '/project/xcx',
			name: 'xcx',
			component: () => import('@/views/project/xcx'),
			meta: {
				title: '小程序开发'
			}
		},
		{
			path: '/project/web',
			name: 'web',
			component: () => import('@/views/project/web'),
			meta: {
				title: '网站开发'
			}
		},
		{
			path: '/project/cases',
			name: 'cases',
			component: () => import('@/views/project/menu/index'),
			meta: {
				title: '合作案例'
			}
		},
		{
			path: '/project/cases/caseInfo',
			name: 'caseInfo',
			component: () => import('@/views/project/menu/caseInfo'),
			meta: {
				title: '案例详情'
			}
		},
		{
			path: '/aboutUs',
			name: 'aboutUs',
			component: () => import('@/views/about/index'),
			meta: {
				title: '网站开发'
			}
		},
		{
			path: '*',
			redirect: '/home'
		},{
			path: '/news',
			name: 'News',
			component: () => import('@/views/news/index'),
			meta: {
				title: '新闻'
			}
		},{
			path: '/us',
			name: 'Us',
			component: () => import('@/views/us/index'),
			meta: {
				title: '关于我们'
			}
		},{
			path: '/industryDetail',
			name: 'IndustryDetail',
			component: () => import('@/views/details/industryDetail'),
			meta: {
				title: '行业详情'
			}
		},{
			path: '/companyDetail',
			name: 'CompanyDetail',
			component: () => import('@/views/details/companyDetail'),
			meta: {
				title: '公司详情'
			}
		},{
			path: '/recruitDetail',
			name: 'RecruitDetail',
			component: () => import('@/views/details/recruitDetail'),
			meta: {
				title: '招聘详情'
			}
		},{
			path: '/planDetail',
			name: 'PlanDetail',
			component: () => import('@/views/details/planDetail'),
			meta: {
				title: '方案详情'
			}
		},
	],
	scrollBehavior(to, from, savedPosition) {
		// savedPosition 会在你使用浏览器前进或后退按钮时候生效
		// 这个跟你使用 router.go() 或 router.back() 效果一致
		// 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
		if (savedPosition) {
			return savedPosition
		} else {
			// 如果不是通过上述行为切换组件，就会让页面回到顶部
			return {
				x: 0,
				y: 0
			}
		}
	}
})

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}